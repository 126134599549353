/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BadRequestException } from '../models';
import { CreatePartnerPayloadDto } from '../models';
import { CreatePartnerResponseDto } from '../models';
import { EmployeeDto } from '../models';
import { EmployeesDto } from '../models';
import { ForbiddenException } from '../models';
import { GetOperationsResponseDto } from '../models';
import { GetOrganizationsResponseDto } from '../models';
import { ModelObject } from '../models';
import { NotFoundException } from '../models';
import { OfferPatchDto } from '../models';
import { OfferPatchResolutionDto } from '../models';
import { OpportunityDataDto } from '../models';
import { OpportunityDto } from '../models';
import { OpportunityPositionDto } from '../models';
import { OrganizationSearchResponseDto } from '../models';
import { PersonDto } from '../models';
import { UnauthorizedException } from '../models';
import { UpdateOrganizationCardVisibilityPayloadDto } from '../models';
import { UpdatePartnerPayloadDto } from '../models';
import { UpdatePartnerResponseDto } from '../models';
import { UploadImageResponseDto } from '../models';
import { GetCouponInfoResponseDTO } from '@/api/models/get-coupon-info-response-dto';

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * В файле будут представлены три колонки - Code, Order date, Activation date
     * @summary Скачать список промо-кодов в формате <b>.csv</b>
     * @param {number} offerId
     * @param {string} [only] Выбрать только \&quot;проданные\&quot;/\&quot;проданные но не активированные\&quot; купоны
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerExportToCSV: async (offerId: number, only?: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling marketControllerExportToCSV.');
      }
      const localVarPath = `/api/2.0/market/offer/{offer_id}/codes.csv`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (only !== undefined) {
        localVarQueryParameter['only'] = only;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    getSoldCoupons: async (offerId: number, only?: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling marketControllerExportToCSV.');
      }
      const localVarPath = `/api/2.0/market/coupons/{offer_id}/sold`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (only !== undefined) {
        localVarQueryParameter['only'] = only;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerApprove: async (offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerApprove.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/approved`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerReject: async (body: OfferPatchResolutionDto, offerPatchId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling offerPatchControllerReject.');
      }
      // verify required parameter 'offerPatchId' is not null or undefined
      if (offerPatchId === null || offerPatchId === undefined) {
        throw new RequiredError('offerPatchId', 'Required parameter offerPatchId was null or undefined when calling offerPatchControllerReject.');
      }
      const localVarPath = `/api/2.0/market/offer-patch/{offer_patch_id}/status/rejected`
        .replace(`{${'offer_patch_id'}}`, encodeURIComponent(String(offerPatchId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список операций (от имени администратора)
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    operationControllerGetOperations: async (phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/operations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (accountIds !== undefined) {
        localVarQueryParameter['account_ids'] = accountIds;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerCreateOpportunity: async (body: OpportunityDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerCreateOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerDeleteOpportunity: async (opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerDeleteOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunitiesPositions: async (body: Array<OpportunityPositionDto>, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerUpdateOpportunitiesPositions.');
      }
      const localVarPath = `/api/2.0/market/opportunity/positions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunity: async (body: OpportunityDataDto, opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerUpdateOpportunity.');
      }
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerUpdateOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUploadImage: async (image: string, opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling opportunityControllerUploadImage.');
      }
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerUploadImage.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}/image`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удалить сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerDeleteOrganizationEmployee: async (organizationNo: number, phone: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerDeleteOrganizationEmployee.');
      }
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling organizationControllerDeleteOrganizationEmployee.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/employee/{phone}`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)))
        .replace(`{${'phone'}}`, encodeURIComponent(String(phone)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удалить роли сотрудника организации
     * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerDeleteOrganizationEmployeeRoles: async (body: Array<string>, organizationNo: number, phone: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling organizationControllerDeleteOrganizationEmployeeRoles.');
      }
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerDeleteOrganizationEmployeeRoles.');
      }
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling organizationControllerDeleteOrganizationEmployeeRoles.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/employee/{phone}/role`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)))
        .replace(`{${'phone'}}`, encodeURIComponent(String(phone)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Поиск организаций по названию
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [name] Полное название организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerFindOrganizations: async (take?: number, name?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/organization/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список всех организаций
     * @param {number} [id] Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {string} [name] Полное название организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetAllOrganizations: async (id?: number, skip?: number, take?: number, order?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/admin/organization`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список операций (от имени организации)
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOperations: async (organizationNo: number, phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerGetOperations.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/operations`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (accountIds !== undefined) {
        localVarQueryParameter['account_ids'] = accountIds;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Сотрудник организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganizationEmployee: async (organizationNo: number, phone: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerGetOrganizationEmployee.');
      }
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling organizationControllerGetOrganizationEmployee.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/employee/{phone}`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)))
        .replace(`{${'phone'}}`, encodeURIComponent(String(phone)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить роли сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganizationEmployeeRoles: async (organizationNo: number, phone: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerGetOrganizationEmployeeRoles.');
      }
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling organizationControllerGetOrganizationEmployeeRoles.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/employee/{phone}/role`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)))
        .replace(`{${'phone'}}`, encodeURIComponent(String(phone)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список сотрудников организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {Array&lt;ModelObject&gt;} [accountNo] Уникальный идентификатор сотрудника организации
     * @param {Array&lt;ModelObject&gt;} [phone] Номера телефонов сотрудников в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganizationEmployees: async (organizationNo: number, skip?: number, take?: number, accountNo?: Array<ModelObject>, phone?: Array<ModelObject>, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerGetOrganizationEmployees.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/employee`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (accountNo) {
        localVarQueryParameter['account_no'] = accountNo;
      }

      if (phone) {
        localVarQueryParameter['phone'] = phone;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавить роли сотрудника организации
     * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerPostOrganizationEmployeeRole: async (body: Array<string>, organizationNo: number, phone: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling organizationControllerPostOrganizationEmployeeRole.');
      }
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerPostOrganizationEmployeeRole.');
      }
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling organizationControllerPostOrganizationEmployeeRole.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/employee/{phone}/role`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)))
        .replace(`{${'phone'}}`, encodeURIComponent(String(phone)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавить сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerPutOrganizationEmployee: async (organizationNo: number, phone: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerPutOrganizationEmployee.');
      }
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling organizationControllerPutOrganizationEmployee.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/employee/{phone}`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)))
        .replace(`{${'phone'}}`, encodeURIComponent(String(phone)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Добавить партнера
     * @param {CreatePartnerPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerCreatePartner: async (body: CreatePartnerPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling partnerControllerCreatePartner.');
      }
      const localVarPath = `/api/2.0/organization`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Установить видимость организации для раздела \"наши партнеры\"
     * @param {UpdateOrganizationCardVisibilityPayloadDto} body
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerSetOrganizationCardVisibility: async (body: UpdateOrganizationCardVisibilityPayloadDto, organizationNo: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling partnerControllerSetOrganizationCardVisibility.');
      }
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling partnerControllerSetOrganizationCardVisibility.');
      }
      const localVarPath = `/api/2.0/{organization_no}/organization-card/visible`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление партнера
     * @param {UpdatePartnerPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerUpdatePartner: async (body: UpdatePartnerPayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling partnerControllerUpdatePartner.');
      }
      const localVarPath = `/api/2.0/organization`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить информацию о пользователе
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUser: async (phone: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling personControllerGetUser.');
      }
      const localVarPath = `/api/2.0/user/{phone}/identity`
        .replace(`{${'phone'}}`, encodeURIComponent(String(phone)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    adminControllerGetCoupon: async (code: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'phone' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling personControllerGetUser.');
      }
      const localVarPath = `/api/2.0/market/coupon/{code}`
        .replace(`{${'code'}}`, encodeURIComponent(code ? String(code) : 'null'));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  return {
    /**
     * В файле будут представлены три колонки - Code, Order date, Activation date
     * @summary Скачать список промо-кодов в формате <b>.csv</b>
     * @param {number} offerId
     * @param {string} [only] Выбрать только \&quot;проданные\&quot;/\&quot;проданные но не активированные\&quot; купоны
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketControllerExportToCSV(offerId: number, only?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .marketControllerExportToCSV(offerId, only, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getSoldCoupons(offerId: number, only?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .getSoldCoupons(offerId, only, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerApprove(offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .offerPatchControllerApprove(offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPatchDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .offerPatchControllerReject(body, offerPatchId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список операций (от имени администратора)
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async operationControllerGetOperations(phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperationsResponseDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .operationControllerGetOperations(phone, skip, take, start, end, order, accountIds, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .opportunityControllerCreateOpportunity(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerDeleteOpportunity(opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .opportunityControllerDeleteOpportunity(opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpportunityPositionDto>>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .opportunityControllerUpdateOpportunitiesPositions(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .opportunityControllerUpdateOpportunity(body, opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUploadImage(image: string, opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .opportunityControllerUploadImage(image, opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Удалить сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerDeleteOrganizationEmployee(organizationNo: number, phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerDeleteOrganizationEmployee(organizationNo, phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Удалить роли сотрудника организации
     * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerDeleteOrganizationEmployeeRoles(body: Array<string>, organizationNo: number, phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelObject>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerDeleteOrganizationEmployeeRoles(body, organizationNo, phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Поиск организаций по названию
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [name] Полное название организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerFindOrganizations(take?: number, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationSearchResponseDto>>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerFindOrganizations(take, name, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список всех организаций
     * @param {number} [id] Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {string} [name] Полное название организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerGetAllOrganizations(id?: number, skip?: number, take?: number, order?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationsResponseDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerGetAllOrganizations(id, skip, take, order, name, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список операций (от имени организации)
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerGetOperations(organizationNo: number, phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperationsResponseDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerGetOperations(organizationNo, phone, skip, take, start, end, order, accountIds, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Сотрудник организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerGetOrganizationEmployee(organizationNo: number, phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerGetOrganizationEmployee(organizationNo, phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить роли сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerGetOrganizationEmployeeRoles(organizationNo: number, phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelObject>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerGetOrganizationEmployeeRoles(organizationNo, phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Список сотрудников организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {Array&lt;ModelObject&gt;} [accountNo] Уникальный идентификатор сотрудника организации
     * @param {Array&lt;ModelObject&gt;} [phone] Номера телефонов сотрудников в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerGetOrganizationEmployees(organizationNo: number, skip?: number, take?: number, accountNo?: Array<ModelObject>, phone?: Array<ModelObject>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeesDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerGetOrganizationEmployees(organizationNo, skip, take, accountNo, phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Добавить роли сотрудника организации
     * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerPostOrganizationEmployeeRole(body: Array<string>, organizationNo: number, phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelObject>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerPostOrganizationEmployeeRole(body, organizationNo, phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Добавить сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerPutOrganizationEmployee(organizationNo: number, phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .organizationControllerPutOrganizationEmployee(organizationNo, phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Добавить партнера
     * @param {CreatePartnerPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerCreatePartner(body: CreatePartnerPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePartnerResponseDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .partnerControllerCreatePartner(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Установить видимость организации для раздела \"наши партнеры\"
     * @param {UpdateOrganizationCardVisibilityPayloadDto} body
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerSetOrganizationCardVisibility(body: UpdateOrganizationCardVisibilityPayloadDto, organizationNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .partnerControllerSetOrganizationCardVisibility(body, organizationNo, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Обновление партнера
     * @param {UpdatePartnerPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerUpdatePartner(body: UpdatePartnerPayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePartnerResponseDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .partnerControllerUpdatePartner(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить информацию о пользователе
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetUser(phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .personControllerGetUser(phone, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getCouponInfo(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCouponInfoResponseDTO>>{
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration)
        .adminControllerGetCoupon(code, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * В файле будут представлены три колонки - Code, Order date, Activation date
     * @summary Скачать список промо-кодов в формате <b>.csv</b>
     * @param {number} offerId
     * @param {string} [only] Выбрать только \&quot;проданные\&quot;/\&quot;проданные но не активированные\&quot; купоны
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerExportToCSV(offerId: number, only?: string, options?: any): AxiosPromise<void> {
      return AdminApiFp(configuration)
        .marketControllerExportToCSV(offerId, only, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Подтвердить обновление предложения
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerApprove(offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return AdminApiFp(configuration)
        .offerPatchControllerApprove(offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отклонить обновление предложения
     * @param {OfferPatchResolutionDto} body
     * @param {number} offerPatchId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any): AxiosPromise<OfferPatchDto> {
      return AdminApiFp(configuration)
        .offerPatchControllerReject(body, offerPatchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список операций (от имени администратора)
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    operationControllerGetOperations(phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any): AxiosPromise<GetOperationsResponseDto> {
      return AdminApiFp(configuration)
        .operationControllerGetOperations(phone, skip, take, start, end, order, accountIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any): AxiosPromise<OpportunityDto> {
      return AdminApiFp(configuration)
        .opportunityControllerCreateOpportunity(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerDeleteOpportunity(opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return AdminApiFp(configuration)
        .opportunityControllerDeleteOpportunity(opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any): AxiosPromise<Array<OpportunityPositionDto>> {
      return AdminApiFp(configuration)
        .opportunityControllerUpdateOpportunitiesPositions(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return AdminApiFp(configuration)
        .opportunityControllerUpdateOpportunity(body, opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUploadImage(image: string, opportunityId: number, options?: any): AxiosPromise<UploadImageResponseDto> {
      return AdminApiFp(configuration)
        .opportunityControllerUploadImage(image, opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerDeleteOrganizationEmployee(organizationNo: number, phone: string, options?: any): AxiosPromise<void> {
      return AdminApiFp(configuration)
        .organizationControllerDeleteOrganizationEmployee(organizationNo, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить роли сотрудника организации
     * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerDeleteOrganizationEmployeeRoles(body: Array<string>, organizationNo: number, phone: string, options?: any): AxiosPromise<ModelObject> {
      return AdminApiFp(configuration)
        .organizationControllerDeleteOrganizationEmployeeRoles(body, organizationNo, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Поиск организаций по названию
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [name] Полное название организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerFindOrganizations(take?: number, name?: string, options?: any): AxiosPromise<Array<OrganizationSearchResponseDto>> {
      return AdminApiFp(configuration)
        .organizationControllerFindOrganizations(take, name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список всех организаций
     * @param {number} [id] Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {string} [name] Полное название организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetAllOrganizations(id?: number, skip?: number, take?: number, order?: string, name?: string, options?: any): AxiosPromise<GetOrganizationsResponseDto> {
      return AdminApiFp(configuration)
        .organizationControllerGetAllOrganizations(id, skip, take, order, name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список операций (от имени организации)
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOperations(organizationNo: number, phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any): AxiosPromise<GetOperationsResponseDto> {
      return AdminApiFp(configuration)
        .organizationControllerGetOperations(organizationNo, phone, skip, take, start, end, order, accountIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Сотрудник организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganizationEmployee(organizationNo: number, phone: string, options?: any): AxiosPromise<EmployeeDto> {
      return AdminApiFp(configuration)
        .organizationControllerGetOrganizationEmployee(organizationNo, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить роли сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganizationEmployeeRoles(organizationNo: number, phone: string, options?: any): AxiosPromise<ModelObject> {
      return AdminApiFp(configuration)
        .organizationControllerGetOrganizationEmployeeRoles(organizationNo, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Список сотрудников организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {Array&lt;ModelObject&gt;} [accountNo] Уникальный идентификатор сотрудника организации
     * @param {Array&lt;ModelObject&gt;} [phone] Номера телефонов сотрудников в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganizationEmployees(organizationNo: number, skip?: number, take?: number, accountNo?: Array<ModelObject>, phone?: Array<ModelObject>, options?: any): AxiosPromise<EmployeesDto> {
      return AdminApiFp(configuration)
        .organizationControllerGetOrganizationEmployees(organizationNo, skip, take, accountNo, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавить роли сотрудника организации
     * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerPostOrganizationEmployeeRole(body: Array<string>, organizationNo: number, phone: string, options?: any): AxiosPromise<ModelObject> {
      return AdminApiFp(configuration)
        .organizationControllerPostOrganizationEmployeeRole(body, organizationNo, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавить сотрудника организации
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerPutOrganizationEmployee(organizationNo: number, phone: string, options?: any): AxiosPromise<EmployeeDto> {
      return AdminApiFp(configuration)
        .organizationControllerPutOrganizationEmployee(organizationNo, phone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Добавить партнера
     * @param {CreatePartnerPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerCreatePartner(body: CreatePartnerPayloadDto, options?: any): AxiosPromise<CreatePartnerResponseDto> {
      return AdminApiFp(configuration)
        .partnerControllerCreatePartner(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Установить видимость организации для раздела \"наши партнеры\"
     * @param {UpdateOrganizationCardVisibilityPayloadDto} body
     * @param {number} organizationNo Уникальный идентификатор организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerSetOrganizationCardVisibility(body: UpdateOrganizationCardVisibilityPayloadDto, organizationNo: number, options?: any): AxiosPromise<boolean> {
      return AdminApiFp(configuration)
        .partnerControllerSetOrganizationCardVisibility(body, organizationNo, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление партнера
     * @param {UpdatePartnerPayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerUpdatePartner(body: UpdatePartnerPayloadDto, options?: any): AxiosPromise<UpdatePartnerResponseDto> {
      return AdminApiFp(configuration)
        .partnerControllerUpdatePartner(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить информацию о пользователе
     * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetUser(phone: string, options?: any): AxiosPromise<PersonDto> {
      return AdminApiFp(configuration)
        .personControllerGetUser(phone, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   * В файле будут представлены три колонки - Code, Order date, Activation date
   * @summary Скачать список промо-кодов в формате <b>.csv</b>
   * @param {number} offerId
   * @param {string} [only] Выбрать только \&quot;проданные\&quot;/\&quot;проданные но не активированные\&quot; купоны
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public marketControllerExportToCSV(offerId: number, only?: string, options?: any) {
    return AdminApiFp(this.configuration)
      .marketControllerExportToCSV(offerId, only, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public getSoldCoupons(offerId: number, only?: string, options?: any) {
    return AdminApiFp(this.configuration)
      .getSoldCoupons(offerId, only, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Подтвердить обновление предложения
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public offerPatchControllerApprove(offerPatchId: number, options?: any) {
    return AdminApiFp(this.configuration)
      .offerPatchControllerApprove(offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отклонить обновление предложения
   * @param {OfferPatchResolutionDto} body
   * @param {number} offerPatchId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public offerPatchControllerReject(body: OfferPatchResolutionDto, offerPatchId: number, options?: any) {
    return AdminApiFp(this.configuration)
      .offerPatchControllerReject(body, offerPatchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список операций (от имени администратора)
   * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public operationControllerGetOperations(phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any) {
    return AdminApiFp(this.configuration)
      .operationControllerGetOperations(phone, skip, take, start, end, order, accountIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать возможность заработать
   * @param {OpportunityDataDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any) {
    return AdminApiFp(this.configuration)
      .opportunityControllerCreateOpportunity(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить возможность заработать
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public opportunityControllerDeleteOpportunity(opportunityId: number, options?: any) {
    return AdminApiFp(this.configuration)
      .opportunityControllerDeleteOpportunity(opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить сортировку списка возможностей заработать
   * @param {Array&lt;OpportunityPositionDto&gt;} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any) {
    return AdminApiFp(this.configuration)
      .opportunityControllerUpdateOpportunitiesPositions(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить возможность заработать
   * @param {OpportunityDataDto} body
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any) {
    return AdminApiFp(this.configuration)
      .opportunityControllerUpdateOpportunity(body, opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузка изображения
   * @param {string} image
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public opportunityControllerUploadImage(image: string, opportunityId: number, options?: any) {
    return AdminApiFp(this.configuration)
      .opportunityControllerUploadImage(image, opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить сотрудника организации
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerDeleteOrganizationEmployee(organizationNo: number, phone: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerDeleteOrganizationEmployee(organizationNo, phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить роли сотрудника организации
   * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerDeleteOrganizationEmployeeRoles(body: Array<string>, organizationNo: number, phone: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerDeleteOrganizationEmployeeRoles(body, organizationNo, phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Поиск организаций по названию
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [name] Полное название организации
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerFindOrganizations(take?: number, name?: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerFindOrganizations(take, name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список всех организаций
   * @param {number} [id] Уникальный идентификатор организации
   * @param {number} [skip] Пропустить n записей
   * @param {number} [take] Количество записей в ответе
   * @param {string} [order] Порядок сортировки записей
   * @param {string} [name] Полное название организации
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerGetAllOrganizations(id?: number, skip?: number, take?: number, order?: string, name?: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerGetAllOrganizations(id, skip, take, order, name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список операций (от имени организации)
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerGetOperations(organizationNo: number, phone?: string, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerGetOperations(organizationNo, phone, skip, take, start, end, order, accountIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Сотрудник организации
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerGetOrganizationEmployee(organizationNo: number, phone: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerGetOrganizationEmployee(organizationNo, phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить роли сотрудника организации
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerGetOrganizationEmployeeRoles(organizationNo: number, phone: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerGetOrganizationEmployeeRoles(organizationNo, phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список сотрудников организации
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {Array&lt;ModelObject&gt;} [accountNo] Уникальный идентификатор сотрудника организации
   * @param {Array&lt;ModelObject&gt;} [phone] Номера телефонов сотрудников в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerGetOrganizationEmployees(organizationNo: number, skip?: number, take?: number, accountNo?: Array<ModelObject>, phone?: Array<ModelObject>, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerGetOrganizationEmployees(organizationNo, skip, take, accountNo, phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавить роли сотрудника организации
   * @param {Array&lt;string&gt;} body Список ролей: employee, employee-admin, market-admin, market-cashier, operations-admin, organization-admin
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerPostOrganizationEmployeeRole(body: Array<string>, organizationNo: number, phone: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerPostOrganizationEmployeeRole(body, organizationNo, phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавить сотрудника организации
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public organizationControllerPutOrganizationEmployee(organizationNo: number, phone: string, options?: any) {
    return AdminApiFp(this.configuration)
      .organizationControllerPutOrganizationEmployee(organizationNo, phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Добавить партнера
   * @param {CreatePartnerPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public partnerControllerCreatePartner(body: CreatePartnerPayloadDto, options?: any) {
    return AdminApiFp(this.configuration)
      .partnerControllerCreatePartner(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Установить видимость организации для раздела \"наши партнеры\"
   * @param {UpdateOrganizationCardVisibilityPayloadDto} body
   * @param {number} organizationNo Уникальный идентификатор организации
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public partnerControllerSetOrganizationCardVisibility(body: UpdateOrganizationCardVisibilityPayloadDto, organizationNo: number, options?: any) {
    return AdminApiFp(this.configuration)
      .partnerControllerSetOrganizationCardVisibility(body, organizationNo, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление партнера
   * @param {UpdatePartnerPayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public partnerControllerUpdatePartner(body: UpdatePartnerPayloadDto, options?: any) {
    return AdminApiFp(this.configuration)
      .partnerControllerUpdatePartner(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить информацию о пользователе
   * @param {string} phone Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public personControllerGetUser(phone: string, options?: any) {
    return AdminApiFp(this.configuration)
      .personControllerGetUser(phone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public getCouponInfo(code: string){
    return AdminApiFp(this.configuration)
      .getCouponInfo(code).then((request) => request(this.axios, this.basePath))
  }
}
